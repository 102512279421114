@import '@farol-ds/tokens/index.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--fds-spacing-3xs);
  margin-top: var(--fds-spacing-6xs);
  margin-bottom: var(--fds-spacing-6xs) !important;
  padding: var(--fds-spacing-6xs) 0 !important;
}
