.ageRangeSelected {
  background-color: var(--fds-color-base-low-1);
  color: var(--fds-color-base-high-pure);

  &__text {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }
}
