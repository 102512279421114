@import '@farol-ds/tokens/index.scss';

.card {
  margin-bottom: var(--fds-spacing-3xs);
}

.list {
  margin-bottom: var(--fds-spacing-3xs);
  padding-left: 0 !important;

  &__button {
    margin-top: var(--fds-spacing-3xs);
    width: 100%;
  }
}

.subtitle {
  margin-bottom: var(--fds-spacing-3xs);
  color: var(--fds-color-base-low-pure);
}

.label {
  font-size: var(--fds-font-size-xs);
  margin-bottom: var(--fds-spacing-5xs);
}

.spinner {
  margin-top: var(--fds-spacing-2xs);
  margin-bottom: var(--fds-spacing-2xs);
  display: flex;
  justify-content: center;
}

.divider {
  margin-top: var(--fds-spacing-2xs);
  margin-bottom: var(--fds-spacing-2xs);
}

@media screen and (max-width: $breakpoint-sm) {
  .button {
    width: 100%;
  }
}
